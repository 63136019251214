import React from  'react';
import gta1 from '../../images/gta/img-1.jpg'
import gta2 from '../../images/gta/img-2.jpg'
import Sectiontitle from '../section-title'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './style.css'



const Getting = () => {
    return(
        <div id="anfahrt" className="gta-area section-padding">
            <Sectiontitle section={'Anfahrt'}/>
            <div className="container">
                <div className="row">
                    <div className="col col-lg-8 offset-lg-2 col-md-8 offset-md-2">
                        <div className="row">
                            <div className="heading col-md-12 col-sm-6">
                                <h4>Heimathof Lembeck</h4>
                                <div className="gta-content">
                                    <p>Kreuzung: Krusenhof, Zur Reithalle, 46286 Dorsten-Lembeck</p>
                                    <p>In der Umgebung finden sich ausreichend Parkmöglichkeiten entlang der Straße.</p>
                                </div>
                                <div className="gta-img">
                                    <div className="location-map center">
                                        <a href="https://goo.gl/maps/fURiQtNm4JxxXG2M8" className="small-button" target="_blank">
                                            <Button className="btn" role="button">📍 Auf Google Maps anzeigen</Button>
                                        </a><br /><br />
                                        
                                        <iframe width="600" height="450" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://www.openstreetmap.org/export/embed.html?bbox=6.9885170459747314%2C51.754352979969575%2C6.998162269592286%2C51.75755407787513&amp;layer=hot&amp;marker=51.75595354213205%2C6.993339657783508"></iframe>
                                    </div>
                                </div>
                            </div>
                            <div className="heading col-md-12 col-sm-6">
                                <h4>Abendlocation</h4>
                                <div className="gta-content">
                                    <p>Stegge 92, 46286 Dorsten-Lembeck</p>
                                    <p>Die Parkmöglichkeiten direkt an der Location sind begrenzt. Ausweichmöglichkeiten finden sich weiter unten beschrieben.</p>
                                </div>
                                <div className="gta-img">
                                    <div className="location-map center">
                                        <a href="https://goo.gl/maps/uq8fy5mcRFg4uKGG8" className="small-button" target="_blank">
                                            <Button className="btn" role="button">📍 Auf Google Maps anzeigen</Button>
                                        </a><br /><br />
                                        <iframe width="650" height="450" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://www.openstreetmap.org/export/embed.html?bbox=7.030391693115235%2C51.7803636299916%2C7.038159370422363%2C51.78356288385419&amp;layer=hot&amp;marker=51.78196328596908%2C7.034275531768799"></iframe>
                                    </div>
                                </div>
                            </div>

                            <div className="heading col-md-12 col-sm-6">
                                <h4>Parkmöglichkeiten Abendlocation</h4>
                                <div className="gta-content">
                                    <p>Hinter der Bushaltestelle "Klein Reken" befindet sich ein großer Schotterparkplatz.<br />Von hier aus kommt man zu Fuß in etwa 5 Minuten zur Abendlocation.</p>
                                </div>
                                <div className="gta-img">
                                    <div className="location-map center">
                                        <a href="https://goo.gl/maps/eRvXd8vQsXRoFyyTA" className="small-button" target="_blank">
                                            <Button className="btn" role="button">📍 Auf Google Maps anzeigen</Button>
                                        </a><br /><br />
                                        <iframe width="425" height="350" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://www.openstreetmap.org/export/embed.html?bbox=7.037179023027421%2C51.785111823144845%2C7.039120942354203%2C51.78591157370359&amp;layer=hot&amp;marker=51.78551170019654%2C7.038149982690811" ></iframe>
                                        
                                    </div>
                                </div>
                            </div>

                            

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Getting;