import React from 'react';
import {Link} from 'react-router-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import 'react-sticky-header/styles.css';
import MobileMenu from '../../components/MobileMenu'



import './style.css'

const Header = () => {

    return(
        
          <div id="home" className="Header_root">
            <div className="header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="logo" >
                                {/* <h2><Link to='/' className="special-font">Aileen & Thomas</Link></h2> */}
                                <h2><AnchorLink href='#home' className="special-font" style={{display:'ruby'}}>Aileen & Thomas</AnchorLink></h2>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="header-menu d-lg-block d-none">
                                <ul className="mobail-menu d-flex">
                                    {/* 
                                    <li><Link to='/'>Home</Link>
                                        <ul className="submenu">
                                            <li><Link to='/home'>Home Style 1</Link></li>
                                            <li><Link to='/home2'>Home Style 2</Link></li>
                                            <li><Link to='/home3'>Home Style 3</Link></li>
                                            <li><Link to='/home4'>Home Style 4</Link></li>
                                            <li><Link to='/home5'>Home video</Link></li>
                                        </ul>
                                    </li> 
                                    */}
                                    {/* 
                                    <li><AnchorLink href='#couple'>Couple</AnchorLink></li>
                                    <li><AnchorLink href='#story'>Story</AnchorLink></li> 
                                    */}                                    
                                    {/* <li><Link to='/'>Home</Link></li> */}
                                    <li><AnchorLink href='#willkommen'>Willkommen</AnchorLink></li>
                                    <li><AnchorLink href='#wir'>Wir</AnchorLink></li>
                                    <li><AnchorLink href='#ablauf'>Ablauf</AnchorLink></li>
                                    <li><AnchorLink href='#anfahrt'>Anfahrt</AnchorLink></li>
                                    <li><AnchorLink href='#fotos'>Fotos</AnchorLink></li>
                                    {/* <li><AnchorLink href='#kontakt'>Kontakt</AnchorLink></li> */}
                                    <li><Link to='#'>Impressum</Link>
                                        <ul className="submenu">
                                            <li><Link to='/impressum'>Impressum</Link></li>
                                            <li><Link to='/datenschutz'>Datenschutz</Link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12">
                            <MobileMenu/>
                        </div>
                    </div>
                </div>
             </div>
          </div>
        
    )
}

export default Header;
