import React, {Component} from 'react';
import ReactFancyBox from 'react-fancybox'
import 'react-fancybox/lib/fancybox.css'
import Sectiontitle from '../section-title'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './style.css'
import { Link } from 'react-router-dom';

import galimg1 from '../../images/gal/img-1.jpg'
import galimg2 from '../../images/gal/img-2.jpg'
import galimg3 from '../../images/gal/img-3.jpg'
import galimg4 from '../../images/gal/img-4.jpg'
import galimg5 from '../../images/gal/img-5.jpg'
import galimg6 from '../../images/gal/img-6.jpg'

class Gallery extends Component {
  render() {

    return (
      <div id="fotos" className="Gallery-section section-padding">
          <Sectiontitle section={'Fotos'}/>
          <div className="container">
            <div className="row" style={{display:'block'}}>
              <div className="col-lg-12">
                <div className="center force-block">
                  <p>Wir würden uns freuen, wenn ihr eure selbstgemachten Fotos mit uns und den anderen Gästen teilt.<br />
                  Je mehr Bilder & Videos wir bekommen, desto besser 😃📸</p>
                  <p>Über folgenden Link könnt Ihr die Fotos hochladen, ansehen und herunterladen:</p><br />
                  
                  
                  
                  <a href="https://nextcloud.roessler.cx/s/XqZJgYmxa8mjqwC" target="_blank">
                    <Button className="btn" role="button" >📷 Fotos teilen</Button>
                  </a>&nbsp;

                  <a href="https://nextcloud.roessler.cx/s/eSTcxnqEP9LHyzf" target="_blank" >
                    <Button className="btn" role="button" style={{margin:'10px'}}>📁 Zum Foto-Ordner</Button>
                  </a>

                  <p>
                    <br /><br />
                    <cite>»Augenblicke sind Fotos in der Gegenwart,<br /> an die man sich in der Zukunft dann erinnert.«</cite>
                  </p>
                </div>
              </div>
            </div>
                      
          </div>
      </div>
    );
  }
}

export default Gallery;