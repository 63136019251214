import React from 'react';
import './style.css'

const Footer = (props) => {
    return(
        <div className={`site-footer ${props.footer}`}>
            <div className="container">
                <div className="row">
                    <div className="text">
                        <span style={{color:'white', fontSize: '1.5rem'}}>
                            <h2 className="big-text">Wir</h2>&nbsp; freuen uns auf <h2 className="big-text">euch!</h2>
                        </span>
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default Footer;