import React from 'react';
import { Link } from 'react-router-dom'
import NavbarBack from '../../components/NavbarBack'
import BlogSidebar from '../../components/BlogSidebar'
import Footer from '../../components/footer'
import mediaimg1 from '../../images/blog/img-7.jpg'
import mediaimg2 from '../../images/blog/img-8.jpg'
import mediaimg3 from '../../images/blog/img-9.jpg'
import mediaimg4 from '../../images/blog/author.jpg'
import Sectiontitle from '../../components/section-title'

import './style.css'

const PrivacyPolicy = () => {

    return (
        <div id="home" className="BlogPage-br">
            <NavbarBack />
            <div className="blog-pg-section">
                <div className="spacer"></div>
                <Sectiontitle section={'Datenschutz'} />  
                <div className="container">
                    <div className="row force-block">
                        <h1>Datenschutzerklärung</h1><br />

                        <h2>1. Datenschutz auf einen Blick</h2>

                        <h3>Allgemeine Hinweise</h3>

                        <p>Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen
                            Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
                            persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz
                            entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.</p>

                        <h3>Datenerfassung auf dieser Website</h3>

                        <p><strong>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</strong></p>
                        <p>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können
                            Sie dem Impressum dieser Website entnehmen.</p>
                        <p><strong>Wie erfassen wir Ihre Daten?</strong></p>
                        <p>Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.&nbsp;B. um
                            Daten handeln, die Sie in ein Kontaktformular eingeben.</p>
                        <p>Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme
                            erfasst. Das sind vor allem technische Daten (z.&nbsp;B. Internetbrowser, Betriebssystem oder Uhrzeit des
                            Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.</p>
                        <p><strong>Wofür nutzen wir Ihre Daten?</strong></p>
                        <p>Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere
                            Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.</p>
                        <p><strong>Welche Rechte haben Sie bezüglich Ihrer Daten?</strong></p>
                        <p>Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
                            gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
                            Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
                            können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das
                            Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
                            zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde
                            zu.</p>
                        <p>Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum
                            angegebenen Adresse an uns wenden.</p>

                        <h3>Analyse-Tools und Tools von Drittanbietern</h3>

                        <p>Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem
                            mit Cookies und mit sogenannten Analyseprogrammen.</p>
                        <p>Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden Datenschutzerklärung.
                        </p>

                        <h2>2. Hosting und Content Delivery Networks (CDN)</h2>

                        <h3>Externes Hosting</h3>

                        <p>Diese Website wird bei einem externen Dienstleister gehostet (Hoster). Die personenbezogenen Daten, die auf
                            dieser Website erfasst werden, werden auf den Servern des Hosters gespeichert. Hierbei kann es sich v. a. um
                            IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen,
                            Webseitenzugriffe und sonstige Daten, die über eine Website generiert werden, handeln.</p>
                        <p>Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und
                            bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten
                            Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
                        </p>
                        <p>Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner Leistungspflichten
                            erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen.</p>
                        <p><strong>Abschluss eines Vertrages über Auftragsverarbeitung</strong></p>
                        <p>Um die datenschutzkonforme Verarbeitung zu gewährleisten, haben wir einen Vertrag über
                            Auftragsverarbeitung mit unserem Hoster geschlossen.</p>

                        <h2>3. Allgemeine Hinweise und Pflichtinformationen</h2>

                        <h3>Datenschutz</h3>

                        <p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
                            personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
                            Datenschutzerklärung.</p>
                        <p>Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten
                            sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende
                            Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie
                            erläutert auch, wie und zu welchem Zweck das geschieht.</p>
                        <p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.&nbsp;B. bei der Kommunikation per
                            E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
                            Dritte ist nicht möglich.</p>

                        <h3>Hinweis zur verantwortlichen Stelle</h3>

                        <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
                        <p>Thomas Rößler<br />
                            Haardstr. 2<br />
                            45721 Haltern am See</p>

                        <p>Telefon: auf Anfrage<br />
                            E-Mail: mail@aileen-und-thomas.de</p>
                        <p>Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen
                            über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen,
                            E-Mail-Adressen o. Ä.) entscheidet.</p>

                        <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>

                        <p>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie
                            können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung
                            per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom
                            Widerruf unberührt.</p>

                        <h3>Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21
                            DSGVO)</h3>

                        <p>WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT
                            DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
                            PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN
                            GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE
                            DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN
                            PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE
                            GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN
                            ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN
                            (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).</p>
                        <p>WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT,
                            JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER
                            WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG
                            STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER
                            DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).</p>

                        <h3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>

                        <p>Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
                            Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres
                            Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht
                            unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.</p>

                        <h3>Recht auf Datenübertragbarkeit</h3>

                        <p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
                            automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format
                            aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen
                            Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>

                        <h3>SSL- bzw. TLS-Verschlüsselung</h3>

                        <p>Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie
                            zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
                            TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des
                            Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer
                            Browserzeile.</p>
                        <p>Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns
                            übermitteln, nicht von Dritten mitgelesen werden.</p>

                        <h3>Auskunft, Löschung und Berichtigung</h3>

                        <p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft
                            über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
                            Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu
                            weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum
                            angegebenen Adresse an uns wenden.</p>

                        <h3>Recht auf Einschränkung der Verarbeitung</h3>

                        <p>Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                            Hierzu können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Das Recht
                            auf Einschränkung der Verarbeitung besteht in folgenden Fällen:</p>
                        <ul>
                            <li>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen
                                wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie
                                das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
                            <li>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht,
                                können Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen.</li>
                            <li>Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung,
                                Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt
                                der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                            </li>
                            <li>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen
                                Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
                                überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen
                                Daten zu verlangen.</li>
                        </ul>

                        <p>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten
                            – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
                            Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen
                            natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses
                            der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.</p>

                        <h3>Widerspruch gegen Werbe-E-Mails</h3>

                        <p>Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von
                            nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die
                            Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung
                            von Werbeinformationen, etwa durch Spam-E-Mails, vor.</p>

                        <h2>4. Datenerfassung auf dieser Website</h2>

                        <h3>Cookies</h3>

                        <p>Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine Textdateien und
                            richten auf Ihrem Endgerät keinen Schaden an. Sie werden entweder vorübergehend für die Dauer
                            einer Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert.
                            Session-Cookies werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben auf
                            Ihrem Endgerät gespeichert bis Sie diese selbst löschen&nbsp;oder eine automatische Lösung
                            durch Ihren Webbrowser erfolgt.</p>
                        <p>Teilweise können auch Cookies von Drittunternehmen auf Ihrem Endgerät gespeichert werden, wenn Sie
                            unsere Seite betreten (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung bestimmter
                            Dienstleistungen des Drittunternehmens (z.B. Cookies zur Abwicklung von Zahlungsdienstleistungen).</p>
                        <p>Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte
                            Webseitenfunktionen ohne diese nicht funktionieren würden (z.B. die Warenkorbfunktion oder die Anzeige
                            von Videos). Andere Cookies dienen dazu das Nutzerverhalten auszuwerten&nbsp;oder Werbung anzuzeigen.</p>
                        <p>Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs (notwendige Cookies) oder zur
                            Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (funktionale Cookies, z. B. für die
                            Warenkorbfunktion) oder zur Optimierung der Webseite (z.B. Cookies zur Messung des Webpublikums)
                            erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere
                            Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von
                            Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung
                            zur Speicherung von Cookies abgefragt wurde, erfolgt die Speicherung der betreffenden Cookies
                            ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO); die Einwilligung ist
                            jederzeit widerrufbar.</p>
                        <p>Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und
                            Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell
                            ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers
                            aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt
                            sein.</p>
                        <p>Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt werden, werden wir Sie hierüber im
                            Rahmen dieser Datenschutzerklärung gesondert informieren und ggf. eine Einwilligung abfragen.</p>

                        <h3>Server-Log-Dateien</h3>

                        <p>Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien,
                            die Ihr Browser automatisch an uns übermittelt. Dies sind:</p>
                        <ul>
                            <li>Browsertyp und Browserversion</li>
                            <li>verwendetes Betriebssystem</li>
                            <li>Referrer URL</li>
                            <li>Hostname des zugreifenden Rechners</li>
                            <li>Uhrzeit der Serveranfrage</li>
                            <li>IP-Adresse</li>
                        </ul>
                        <p>Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.</p>
                        <p>Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
                            berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website –
                            hierzu müssen die Server-Log-Files erfasst werden.</p>

                        <h3>Kontaktformular</h3>

                        <p>Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular
                            inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall
                            von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</p>
                        <p>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit
                            der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher
                            Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem
                            berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f
                            DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.</p>
                        <p>Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung
                            auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung
                            entfällt (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche
                            Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.</p>

                        <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>

                        <p>Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus
                            hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
                            gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</p>
                        <p>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit
                            der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher
                            Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem
                            berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f
                            DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.</p>
                        <p>Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur
                            Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die
                            Datenspeicherung entfällt (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
                            gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben
                            unberührt.</p>

                        <h3>Kommentarfunktion auf dieser Website</h3>

                        <p>Für die Kommentarfunktion auf dieser Seite werden neben Ihrem Kommentar auch Angaben zum Zeitpunkt der
                            Erstellung des Kommentars, Ihre E-Mail-Adresse und, wenn Sie nicht anonym posten, der von Ihnen
                            gewählte Nutzername gespeichert.</p>
                        <p><strong>Speicherung der IP-Adresse</strong></p>
                        <p>Unsere Kommentarfunktion speichert die IP-Adressen der Nutzer, die Kommentare verfassen. Da wir Kommentare
                            auf dieser Website nicht vor der Freischaltung prüfen, benötigen wir diese Daten, um im Falle von
                            Rechtsverletzungen wie Beleidigungen oder Propaganda gegen den Verfasser vorgehen zu können.</p>
                        <p><strong>Abonnieren von Kommentaren</strong></p>
                        <p>Als Nutzer der Seite können Sie nach einer Anmeldung Kommentare abonnieren. Sie erhalten eine
                            Bestätigungsemail, um zu prüfen, ob Sie der Inhaber der angegebenen E-Mail-Adresse sind. Sie
                            können diese Funktion jederzeit über einen Link in den Info-Mails abbestellen. Die im Rahmen des
                            Abonnierens von Kommentaren eingegebenen Daten werden in diesem Fall gelöscht; wenn Sie diese Daten
                            für andere Zwecke und an anderer Stelle (z.&nbsp;B. Newsletterbestellung) an uns übermittelt
                            haben, verbleiben diese Daten jedoch bei uns.</p>
                        <p><strong>Speicherdauer der Kommentare</strong></p>
                        <p>Die Kommentare und die damit verbundenen Daten (z.&nbsp;B. IP-Adresse) werden gespeichert und verbleiben auf
                            dieser Website, bis der kommentierte Inhalt vollständig gelöscht wurde oder die Kommentare aus
                            rechtlichen Gründen gelöscht werden müssen (z.&nbsp;B. beleidigende Kommentare).</p>
                        <p><strong>Rechtsgrundlage</strong></p>
                        <p>Die Speicherung der Kommentare erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie
                            können eine von Ihnen erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung
                            per E-Mail an uns. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge
                            bleibt vom Widerruf unberührt.</p>

                        <p>Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a></p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PrivacyPolicy;