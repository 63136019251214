import React, { Component } from "react";
import AllRoute from '../router'
import './App.css';

class App extends Component {

  render(){
    
      return (
        <div className="App">
          <AllRoute/>
        </div>
      );
  }
}

export default App;
