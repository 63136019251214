import React from 'react'
import Sectiontitle from '../section-title'
import './style.css'


const Couple = (props) => {
    return(
        <div id="wir" className={`couple about-wrap ${props.couple}`}>
            <div className="couple-area section-padding">
                <Sectiontitle section={'Wir'}/>
                <div className="container">
                    <div className="couple-wrap">
                        <div className="">
                            <div className="couple-single">
                                <div className="">
                                    <div className="couple-img">
                                        <img src={props.coupleimg1} alt="thumb"/>
                                    </div>
                                    <div className="couple-text">
                                        <div className="couple-content">
                                            <h3 className="special-font font-factor-5">Aileen & Thomas</h3>
                                            <p>
                                                Bald ist es soweit und wir zwei gehen gemeinsam den nächsten Schritt.<br /><br />
                                                Einen so wichtigen Lebensabschnitt geht man nicht allein, daher freuen wir uns sehr, dass ihr diesen Moment mit uns gemeinsam erlebt und mit uns feiert.
                                            </p>
                                            <br />
                                            <cite>»Der Liebe zu begegnen, ohne sie zu suchen, ist der einzige Weg, sie zu finden.«</cite>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             
        </div>
        
    )
}

export default Couple;