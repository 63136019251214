import React, { Component } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Collapse, CardBody, Card } from 'reactstrap';
import {Link} from 'react-router-dom'
import './style.css';

export default class MobileMenu extends Component {

    state = {
        isMenuShow: false,
        isOpen: false,
    }

    menuHandler = () => {
        this.setState({
            isMenuShow: !this.state.isMenuShow
        })
    }

    setIsOpen = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }


    render() {

        const { isMenuShow, isOpen } = this.state;

        return (
            <div>
                <div className={`mobileMenu ${isMenuShow ? 'show' : ''}`}>
                    <div className="clox" onClick={this.menuHandler}><span style={{fontSize:'18pt'}}>✖️</span></div>
                    <div className="logo2">
                        <h2><Link to='/' className="special-font">Aileen & Thomas</Link></h2>
                    </div>
                    <ul className="responsivemenu">
                        
                        <li><AnchorLink href='#willkommen'>Willkommen</AnchorLink></li>
                        <li><AnchorLink href='#wir'>Wir</AnchorLink></li>
                        <li><AnchorLink href='#ablauf'>Ablauf</AnchorLink></li>
                        <li><AnchorLink href='#anfahrt'>Anfahrt</AnchorLink></li>
                        <li><AnchorLink href='#fotos'>Fotos</AnchorLink></li>
                        <li><p onClick={this.setIsOpen} style={{marginLeft:'1rem', fontWeight:'500'}}>Impressum<i className="fa fa-angle-right" aria-hidden="true" style={{top:'5px'}}></i></p></li>
                        <Collapse isOpen={isOpen}>
                            <Card>
                                <CardBody>
                                <li><Link to='/impressum#top'>Impressum</Link></li>
                                <li><Link to='/datenschutz#top'>Datenschutz</Link></li>
                                </CardBody>
                            </Card>
                        </Collapse>
                    </ul>
                    
                </div>

                <div className="showmenu" onClick={this.menuHandler}><i className="fa fa-bars" aria-hidden="true"></i></div>         
            </div>
        )
    }
}
