import React from 'react';
import {Link} from 'react-router-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import 'react-sticky-header/styles.css';
import MobileMenuBack from '../MobileMenuBack'



import './style.css'

const HeaderBack = () => {

    return(
        
          <div id="home" className="Header_root">
            <div className="header" style={{background: '#455139'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="logo" >
                                {/* <h2><Link to='/' className="special-font">Aileen & Thomas</Link></h2> */}
                                <h2><Link to='/' className="special-font">Aileen & Thomas</Link></h2>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="header-menu d-lg-block d-none">
                                <ul className="mobail-menu d-flex">
                                    <li>
                                        <Link to='/'> ← Zurück</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12">
                            <MobileMenuBack/>
                        </div>
                    </div>
                </div>
             </div>
          </div>
        
    )
}

export default HeaderBack;
