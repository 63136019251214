
import React, { useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Sectiontitle from '../section-title'
import './style.css'

const Welcome = (props) => {
  const {
    className
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (

    <div id="willkommen" className={`welcome-area ${props.welcome}`}>
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="welcome-content">
                      <Sectiontitle section={'Herzlich Willkommen'} />
                      <p>Auf dieser Webseite versorgen wir euch mit den wichtigsten Informationen rund um unsere Hochzeit. Schaut also regelmäßig, insbesondere kurz vor und nach unserem großen Tag rein.</p>
                      
                      <div className="video">
                      {/* NouHwmvaAJc / Wq2rn2eIRug*/}
                        <iframe className="video-frame" src="https://www.youtube-nocookie.com/embed/Wq2rn2eIRug?controls=2&showinfo=0&color=white&iv_load_policy=3&modestbranding=1&rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

  );
}

export default Welcome;