
import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody} from 'reactstrap';
import Sectiontitle from '../section-title'
import imageWedding from '../../images/illustration_hochzeit.png'
import imageChampagneReception from '../../images/illustration_sektempfang.png'
import imageDinner from '../../images/illustration_abendessen.png'
import imageParty from '../../images/illustration_party.png'
import timeline from '../../images/timeline2.png'
import car from '../../images/car.png'



import './style.css'

const Location = (props) => {

    const {
        className
    } = props;

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <div id="ablauf" className="event-section section-padding">
            <Sectiontitle section={'Ablauf'} />
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="tabs-site-button">
                            <div className="event-tabs">
                                <div className="col-md-12 col-12">
                                        
                                    <p className="center">
                                        <strong>Zusammenfassung</strong>
                                    </p>

                                    <div className="event-img timeline">
                                        <img src={timeline} alt="Ablaufplan" />
                                    </div>

                                    <br /><br /><br />
                                    <p className="center">
                                        <strong>Der Tagesablauf im Detail </strong>
                                    </p>

                                    <div className="event-wrap">
                                        <div className="row">
                                            <div className="col-lg-5 col-md-12 col-12">
                                                <div className="event-img">
                                                    <img src={imageWedding} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-md-12 col-12">
                                                <div className="event-text">
                                                    <h2 className="font-factor-2-5">Freie Trauung</h2>
                                                    <h2 className="font-factor-2">14:30 Uhr</h2>
                                                    <p>Am Heimathof in Lembeck beginnen wir unseren Tag mit der Trauung👰🤵 und tauschen natürlich auch die Ringe 💍<br /><br />
                                                        Wer zu spät kommt, verpasst bekanntlich das Beste 🙃<br />Seid daher bitte pünktlich 🕝 
                                                    </p>
                                                    <p><strong>Heimathof Lembeck</strong><br /> Kreuzung: Krusenhof, Zur Reithalle, 46286 Dorsten-Lembeck</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="event-wrap">
                                        <div className="row">
                                            <div className="col-lg-7 col-md-12 col-12">
                                                <div className="event-text event-text-2">
                                                    <h2 className="font-factor-2-5">Sektempfang & Snacks</h2>
                                                    <h3 className="font-factor-2">(direkt nach der Trauung)</h3>
                                                    <p>Im Anschluss an die Trauung gibt es direkt vor Ort einen Sektempfang 🥂<br />
                                                    Auch der kleine Hunger 🍪🍰 kommt nicht zu kurz.<br /><br />
                                                    Außerdem stehen ein paar Fotos 📷🌳 mit Euch auf dem Programm.<br /><br />
                                                    🔜 Irgendwann im Verlaufe des Nachmittags geht es dann weiter zur Abendlocation.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-md-12 col-12">
                                                <div className="event-img">
                                                    <img src={imageChampagneReception} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="center">
                                        <br /><img src={car} style={{width:'300px'}} alt="" />
                                    </div>

                                    <div className="event-wrap">
                                        <div className="row">
                                            <div className="col-lg-5 col-md-12 col-12">
                                                <div className="event-img">
                                                    <img src={imageDinner} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-md-12 col-12">
                                                <div className="event-text" style={{paddingTop:'7px'}}>
                                                    <h2 className="font-factor-2-5">Abendessen</h2>
                                                    <h3 className="font-factor-2">ca. 18:30 Uhr</h3>
                                                    <p>Inzwischen habt ihr euch bereits mit der neuen Location vertraut gemacht und werdet von unseren Kellnern mit Getränken 🍺🥤☕ versorgt.<br /><br />
                                                    Da so langsam das Hungergefühl 😋🍽️ eingesetzt haben sollte, heißt es nun schnell hinsetzen oder besser gleich ran ans Buffet 🍖🐟🥔🥦🍅🍕🍝🥗🍮</p>
                                                    <p><strong>Abendlocation</strong><br /> Stegge 92, 46286 Dorsten-Lembeck</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="event-wrap">
                                        <div className="row">
                                            <div className="col-lg-7 col-md-12 col-12">
                                                <div className="event-text event-text-2">
                                                    <h2 className="font-factor-2-5">Party</h2>
                                                    <h3 className="font-factor-2">(nach dem Essen)</h3>
                                                    <p>Gut gestärkt kann nun die Party beginnen 🍻🎉🙌 <br /><br />
                                                    Schwingt das Tanzbein 💃, genießt die Musik 🪩🎵, und sorgt mit uns für einen unvergesslichen Abend! 🤩</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-md-12 col-12">
                                                <div className="event-img">
                                                    <img src={imageParty} alt="" />
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Location;